import React, { ReactChildren, useState } from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import Navigation from "./Navigation";
import OverlayMenu from "./OverlayMenu";
import Colors from "../constants/Colors";
import "./Link.css";
import "./Layout.css";
import Theme from "../constants/Theme";

interface LayoutProps {
  children: JSX.Element | JSX.Element[] | null;
  navTheme?: Theme;
  override?: string;
  showNav?: boolean;
}

const Layout = (props: LayoutProps) => {
  const { children, override, navTheme = Theme.light, showNav = true } = props;
  const [showMenu, setMenu] = useState(false);

  const menuPress = () => {
    setMenu(!showMenu);
  };

  const renderSite = () => (
    <div className="site" style={styles.zeroIndex}>
      <div className={override}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );

  const renderMenu = (menuPress) => {
    return (
      <div>
        <OverlayMenu onPress={menuPress} />
        {renderSite()}
      </div>
    );
  };

  return (
    <div className="site">
      {showNav && <Navigation theme={navTheme} menuPress={menuPress} />}
      {showMenu ? renderMenu() : renderSite()}
    </div>
  );
};

const styles = {
  zeroIndex: { zIndex: 0, backgroundColor: Colors.white },
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
