enum Endpoint {
  baseUrl = "https://sharpml.com",
  localHost = "http://localhost:8000",
  imageObjectDetection = "/demos/image_object_detection",
  imageObjectDetectionAerial = "/demos/image_object_detection_aerial",
  imageClassification = "/demos/image_classification",
  predict = "api/v0/demos/predict",
  home = "/",
  blog = "/blog",
  contact = "/contact",
  demos = "/demos",
  terms = "/terms",
  linkedin = "https://www.linkedin.com/company/sharpml/",
}

export default Endpoint;
