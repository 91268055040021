import React from "react";
import StyleNavLink from "../styles/StyleNavLink";
import Theme from "../constants/Theme";
import "./Navigation.css";

const Slash = ({ theme, collapses }) => {
  const textColor =
    theme === Theme.dark ? StyleNavLink.dark : StyleNavLink.light;
  const className = collapses ? "collapses" : null;
  return (
    <p
      className={className}
      style={{
        ...StyleNavLink.text,
        ...StyleNavLink.link,
        ...textColor,
      }}
    >
      /
    </p>
  );
};

export default Slash;
