import React from "react";
import { Link } from "gatsby";
import Logo from "./Logo";
import NavLink from "./NavLink";
import Slash from "./Slash";
import Endpoint from "../constants/Endpoint";
import Strings from "../constants/Strings";
import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import Dropdown from "./Dropdown";
import "./Navigation.css";
import "./Layout.css";
import "./Link.css";
import "./Shadow.css";

const Navigation = ({ theme }) => {
  const backgroundColor =
    theme === Theme.dark ? Colors.transparent : Colors.white;
  const computedHeaderStyle = { ...styles.headerBackground, backgroundColor };
  return (
    <header className="nav marginStandard" style={computedHeaderStyle}>
      <Link to={Endpoint.home} className="link">
        <Logo theme={theme} />
      </Link>
      <Dropdown
        title={Strings.Models.title}
        theme={theme}
        screenStyles={styles.float}
        destination={Endpoint.demos}
      >
        <Link to={Endpoint.imageClassification}>
          {Strings.Models.ImageClassification.title}
        </Link>
        <Link to={Endpoint.imageObjectDetection}>
          {Strings.Models.ImageObjectDetection.title}
        </Link>
        <Link to={Endpoint.imageObjectDetectionAerial}>
          {Strings.Models.ImageObjectDetectionAerial.title}
        </Link>
      </Dropdown>
      <Slash theme={theme} />
      <NavLink theme={theme} to={Endpoint.blog}>
        {Strings.Blog.title}
      </NavLink>
      <Slash theme={theme} />
      <NavLink theme={theme} to={Endpoint.contact}>
        {Strings.Contact.title}
      </NavLink>
    </header>
  );
};

const styles = {
  headerBackground: {
    zIndex: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "1.0rem",
    paddingBottom: "1.0rem",
  },
  float: {
    marginLeft: "auto",
  },
};

export default Navigation;
