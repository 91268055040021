enum Colors {
  black = "#06111c",
  white = "#FFFFFF",
  primary = "#FA2626",
  blueDark = "#252F3F",
  blueDarkOpacity = "rgba(37, 47, 63, 0.8)",
  grayLight = "#F0F1F2",
  grayPlaceholder = "#A8A8A8",
  grayDark = "#ABAFB3",
  green = "#60D9A0",
  transparent = "transparent",
}

export default Colors;
