import React from "react";
import Colors from "../constants/Colors";
import "./OverlayMenu.css";

const OverlayMenu = (menuPress) => (
  <header
    onClick={menuPress}
    className="overlayMenuCollapse"
    style={styles.overlayMenu}
  ></header>
);

const navItemStyle = {
  menuText: {
    fontSize: "1.2rem",
  },
  menuContainer: {
    marginBottom: "20px",
  },
};

const styles = {
  menuItem: {
    ...navItemStyle.menuText,
    ...navItemStyle.menuContainer,
  },
  overlayMenu: {
    backgroundColor: Colors.white,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    height: "100%",
  },
};

export default OverlayMenu;
