import React from "react";
import { Link } from "gatsby";
import StyleNavLink from "../styles/StyleNavLink";
import Theme from "../constants/Theme";
import Colors from "../constants/Colors";
import "./Dropdown.css";
import "./Link.css";

interface DropDownProps {
  title: string;
  children: JSX.Element | JSX.Element[] | null;
  screenStyles: React.CSSProperties;
  theme: Theme;
  destination: string;
}

const Dropdown = (props: DropDownProps) => {
  const { title, screenStyles, children, theme, destination } = props;
  const textColor = theme === Theme.dark ? Colors.white : Colors.blueDark;
  return (
    <div className="dropdown" style={screenStyles}>
      <Link className="link" to={destination}>
        <p
          className="dropbtn"
          style={{
            ...StyleNavLink.link,
            color: textColor,
          }}
        >
          {title}
        </p>
      </Link>
      <div className="dropdown-content">{children}</div>
    </div>
  );
};

export default Dropdown;
