import React from "react";
import { Link } from "gatsby";
import Logo from "../components/Logo";
import Slash from "./Slash";
import Strings from "../constants/Strings";
import Endpoint from "../constants/Endpoint";
import Colors from "../constants/Colors";
import Theme from "../constants/Theme";
import NavLink from "./NavLink";
import Dropdown from "./Dropdown";
import "./Layout.css";
import "./Navigation.css";
import "./Link.css";

const Footer = () => (
  <footer style={styles.footer}>
    <div className="marginStandard" style={styles.footerContainer}>
      <div style={styles.linkContainer}>
        <Link to={Endpoint.home} className="link">
          <Logo theme={Theme.dark} />
        </Link>
        <Link className="link" style={styles.text} to={Endpoint.terms}>
          {Strings.Terms.title}
        </Link>
        <p style={styles.text}>{yearString()}</p>
      </div>
      <div style={{ ...styles.float, ...styles.linkContainer }}>
        <div style={styles.footerContainer}>
          <Dropdown
            title={Strings.Models.title}
            theme={Theme.dark}
            screenStyles={styles.float}
            destination={Endpoint.demos}
          >
            <Link to={Endpoint.imageClassification}>
              {Strings.Models.ImageClassification.title}
            </Link>
            <Link to={Endpoint.imageObjectDetection}>
              {Strings.Models.ImageObjectDetection.title}
            </Link>
          </Dropdown>
          <Slash theme={Theme.dark} />
          <NavLink theme={Theme.dark} to={Endpoint.blog}>
            {Strings.Blog.title}
          </NavLink>
          <Slash theme={Theme.dark} />
          <NavLink theme={Theme.dark} to={Endpoint.contact}>
            {Strings.Contact.title}
          </NavLink>
        </div>
      </div>
    </div>
  </footer>
);

const yearString = () => {
  const d = new Date();
  const year = d.getFullYear();
  return `${Strings.Footer.copyright} ${year} ${Strings.Footer.rightsReserved}`;
};

const styles = {
  footer: {
    backgroundColor: Colors.blueDark,
    marginTop: "auto",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  marginNone: { marginLeft: 0 },
  text: {
    color: Colors.white,
    fontSize: "0.7rem",
  },
  float: {
    marginLeft: "auto",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
};

export default Footer;
