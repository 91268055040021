const Strings = {
  Blog: {
    title: "Blog",
    description: "From our blog",
  },
  LinkedIn: {
    title: "LinkedIn",
  },
  Logo: {
    sharp: "Sharp",
    ml: "ML",
  },
  Home: {
    title: "Make smarter decisions with Artificial Intelligence",
    subtitle: "We build AI solutions for image and video recognition.",
  },
  Capabilities: {
    title: "Our capabilities",
    dataCollection: "Data collection, normalization, and bias analysis",
    classification: "Image & Video classification",
    detection: "Image & Video object detection",
    tracking: "Image & Video object tracking & counting",
    counting: "Image & Video segmentation / masking",
    ocr: "Optical character recognition",
    productization: "AI model productization, deployment, monitoring",
  },
  Contact: {
    title: "Contact",
    message: "Schedule a chat to see how we can help with your next project.",
    description: "We provide the following services & products:",
    Consulting: {
      title: "Project consulting, flat rate or by the hour",
    },
    VisionAPIs: {
      title: "Production-ready vision REST APIs",
    },
    ModelHosting: {
      title: "AI model hosting platform",
    },
  },
  Models: {
    title: "Demos",
    try: "Try our demos",
    tryThis: "Try this demo",
    error: "There was an error fetching your results.",
    errorSize: "Images must be less than 5MB.",
    Home: {
      title: "Image Recognition Demos",
      description:
        "In addition to consulting services, we offer a suite of production-ready image recognition REST APIs. Take our APIs for a test-drive by trying the following demos.",
      callout1:
        "Like what you see? Drop your email in the contact form below to get access.",
      callout2:
        "Need a custom model? Reach out to schedule a chat with our team.",
    },
    ImageClassification: {
      title: "Image classification",
      subtitle:
        "This image recognition model analyzes an input image, then returns descriptive labels for 1,000 unique categories. Useful for e-commerce, social media, and enterprise search.",
      description:
        "This machine learning model analyzes an input image, then returns descriptive labels for 1,000 unique categories. Image classifiers like this are useful and customizable for a wide variety of applications; like manufacturing defect detection, e-commerce visual search, medical imaging, and more.",
      Form: {
        title: "Need a custom image classifier?",
      },
    },
    ImageObjectDetection: {
      title: "Image object detection (General)",
      subtitle:
        "This image recognition model analyzes an input image, then returns descriptive labels and locations for 172 unique categories. Useful for people & traffic counting, as well as enterprise search.",
      description:
        "This machine learning model analyzes an input image, then returns descriptive labels and locations for 172 unique categories. Object detectors like this are useful and customizable for a wide variety of applciations; like self-driving cars, facial detection, pedistrian counting, drone inspections, and more.",
      Form: {
        title: "Need a custom object detector?",
      },
    },
    ImageObjectDetectionAerial: {
      title: "Image object detection (Aerial)",
      description:
        "This machine learning model analyzes an input image, then returns descriptive labels and locations for 6 unique categories. Aerial object detectors like this are useful and customizable for a wide variety of applciations; like drone inspections, drone surveys, land-use analysis, and traffic counting. Thanks to the Roboflow team for making the dataset used to train this model public.",
      subtitle:
        "This image recognition model analyzes an input image, then returns descriptive labels and locations for 6 unique categories. Useful for drone applications.",
      Form: {
        title: "Need a custom object detector?",
      },
    },
  },
  WhoWeAre: {
    title: "About us",
    subtitle:
      "We’re a team of AI practicioners and software engineers on a mission to bring cutting-edge image recognition tech to everyday businesses.",
    value:
      "With decades of consulting and software engineering experience, our team can help your company take a project from idea to production.",
  },
  OurServices: {
    title: "Working with us",
    description:
      "Businesses hire us to accelerate AI/ML development efforts — augmenting their internal teams or letting us own AI projects end-to-end.",
    Quick: {
      title: "Consulting",
      message:
        "Develop 100% custom image and video recognition applications by hiring our team on a project or ongoing basis.",
    },
    Flexible: {
      title: "Image Recognition APIs",
      message:
        "Leverage our growing suite of image recognition REST APIs, which are useful for a variety of industries & applications.",
    },
    Customizable: {
      title: "Model hosting",
      message:
        "Host your existing machine learning model on our proprietary hosting solution, reducing your DevOps costs.",
    },
  },
  Tools: {
    title: "We use the best tools in the industry",
  },
  CallToAction: {
    title: "Schedule a chat",
    subtitle:
      "Drop your email below to get started. We'll respond shortly with an email to schedule a free consultation.",
  },
  FormEmail: {
    submitting: "Submitting ...",
    submitted: "Thank you, we'll reach out shortly.",
    error: "There was an error with your submission.",
    label: "Email",
    button: "Schedule a chat",
    placeholder: "your@email.com",
  },
  Nav: {
    instagram: "Instagram",
    twitter: "Twitter",
    new: "New!",
  },
  Footer: {
    copyright: "©",
    rightsReserved: "SharpML. All Rights Reserved."
  },
  Founders: {
    brandon: "Brandon Wan",
    garett: "Garett Johnson",
    kurt: "Kurt McIntire",
  },
  Missing: {
    title: "404: Not Found",
    message: "Hey, SharpML here. Looks like that page is missing.",
  },
  Tags: {
    title: "Tags",
  },
  Terms: {
    title: "API Terms of Service",
  },
  Snippet: {
    ellipses: "...",
    title: "Read more",
  },
  Threshold: {
    title: "Accuracy threshold:",
  },
  Loading: {
    title: "Loading",
  },
};

export default Strings;
