import React from "react";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import Theme from "../constants/Theme";

const Logo = ({ theme }) => {
  const textColor = theme === Theme.light ? Colors.blueDark : Colors.white;
  const computedLeft = { ...styles.logoLeft, color: textColor };
  return (
    <div>
      <span style={computedLeft}>{`${Strings.Logo.sharp}`}</span>
      <span style={styles.logoRight}>
        <i>{Strings.Logo.ml}</i>
      </span>
    </div>
  );
};

const styles = {
  logoLeft: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  logoRight: {
    fontSize: "1.1rem",
    fontWeight: 600,
    color: Colors.primary,
  },
};

export default Logo;
