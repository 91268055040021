import React from "react";
import { Link } from "gatsby";
import StyleNavLink from "../styles/StyleNavLink";
import Theme from "../constants/Theme";
import "./Link.css";

const NavLink = ({
  external,
  to,
  theme,
  collapses,
  screenStyles,
  children,
}) => {
  const textColor =
    theme === Theme.dark ? StyleNavLink.dark : StyleNavLink.light;
  const className = collapses ? "link, collapses" : "link";
  if (external) {
    return (
      <a
        className={className}
        href={external}
        style={{
          textDecoration: `none`,
          ...StyleNavLink.text,
          ...StyleNavLink.link,
          ...textColor,
          ...screenStyles,
        }}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      className={className}
      to={to}
      style={{
        textDecoration: `none`,
        ...StyleNavLink.text,
        ...StyleNavLink.link,
        ...textColor,
        ...screenStyles,
      }}
    >
      {children}
    </Link>
  );
};

export default NavLink;
