import Colors from "../constants/Colors";

const StyleNavLink = {
  dark: {
    color: Colors.white,
  },
  light: {
    color: Colors.blueDark,
  },
  text: {
    fontSize: "0.8rem",
  },
  link: {
    textDecoration: `none`,
    fontWeight: 600,
    color: Colors.white,
    marginLeft: "0.75rem",
    marginBottom: "0px",
    letterSpacing: "1px",
  },
};

export default StyleNavLink;
